"use strict";

$(document).ready(function() {
    $('#frmImportBsFile').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            mon_fichier: {
                validators: {
                    notEmpty: {
                        message: "Veuillez sélectionner un fichier au format CSV"
                    } ,
                    file: {
                        extension: 'csv',
                        maxSize: 8000000,
                        message: 'Fichier sélectionné non valide'
                    }
                }
            },
            Type_Fichier: {
                validators: {
                    notEmpty: {
                        message: "Le type de fichier est requis"
                    }
                }
            }
        }
    });


    $('#frmImportRem').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            Fichier_Import: {
                validators: {
                    notEmpty: {
                        message: "Veuillez sélectionner un fichier au format CSV"
                    } ,
                    file: {
                        extension: 'csv',
                        maxSize: 5000000,
                        message: 'Fichier sélectionné non valide'
                    }
                }
            },
            date_Import: {
                validators: {
                    notEmpty: {
                        message: "Le date d'import est requise"
                    }
                }
            },
            Type_Fichier: {
                validators: {
                    notEmpty: {
                        message: "Le type de fichier est requis"
                    }
                }
            }
        }
    });
});