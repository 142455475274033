"use strict";

$(document).ready(function() {
    $('#frmLogin').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            login: {
                validators: {
                    notEmpty: {
                        message: "L'identifiant est requis"
                    }
                }
            },
            password: {
                validators: {
                    notEmpty: {
                        message: "Le mot de passe est requis"
                    }
                }
            }
        }
    });
});