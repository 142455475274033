/**
 * Created by florent.benoit on 29/05/2017.
 */

$(document).ready(function() {

    $('#btnDownload').click(function () {
        $('#submitDownload').click();
    });

});

