/**
 * Created by florent.benoit on 19/01/2017.
 */

function calcPrimeByPoint(bscnt){
//Prime en fonction du nombre de point

    $primeVar = 0;
    if (bscnt < 40) {
        $primeVar = 0;
    }
    else if (bscnt < 55) {
        $primeVar = 300;
    }
    else if (bscnt < 75) {
        $primeVar = 500;
    }
    else if (bscnt < 100) {
        $primeVar = 1000;
    }
    else if (bscnt < 125) {
        $primeVar = 1250;
    }
    else if (bscnt < 150) {
        $primeVar = 1500;
    }
    else if (bscnt >= 150) {
        $primeVar = 2000;
    }

    return $primeVar;
}

function changeTypeUser(){
    if($("#typeuser").val() == 'vendeur'){
        $('.structure').show();
        $('.users').show();
        $('.user_operateur').hide();
    }
    else if($("#typeuser").val() == 'operateur'){
        $('.structure').hide();
        $('.users').hide();
        $('.user_operateur').show();
    }
}

$(document).ready(function() {

    changeTypeUser();

    //Commission à verser filtre auto
    $('#frmSearchCommission :input').change(function() {

        user = $('#users').val();
        user_operateur = $('#user_operateur').val();
        date = $('#signature_date').val();

        if($("#typeuser").val() == 'vendeur' && user != "" && date != ""){
            $('#submitFind').click();
        }
        else if($("#typeuser").val() == 'operateur' && user_operateur != "" && date != ""){
            $('#submitFind').click();
        }

    });

    $('#btnAppyAbsenteisme').click(function() {
        $('#absenteisme').val($('#taux_presence').val());
        $('#submitFind').click();
    });

    $('#typeuser').change(function() {
        changeTypeUser();
    });


    $('#LstCommission tr').not(':first').hover(function() {
        $(this).find($('.payebyid')).removeClass('uk-hidden');
    }, function() {
        $(this).find($('.payebyid')).addClass('uk-hidden');
    });

    if ($('#LstCommission').find('tr').length <= 1)
    {
        $('#btnPayeCommission').hide();
    }else{
        $('#btnPayeCommission').show();
    }

    $('#LstCommission').find("[name='commissioncheckbox']").change(function(){
        if($(this).is(':checked')){
            $(this).closest('tr').removeClass('noprint');
        }
        else{
            $(this).closest('tr').addClass('noprint')
        }

        CountBSEni = 0;
        CountBSAntargaz = 0;
        CountBSPlanet = 0;
        CountBSEngie = 0;
        CountBSLagardere = 0;
        CountBSSFR = 0;
        CountDerem = 0;
        CountKiwatch = 0;
        CountSwitchcam = 0;
        CountBS = 0;
        CountBSValide = 0;
        TotauxBSEni = 0;
        TotauxBSAntargaz = 0;
        TotauxBSPlanet = 0;
        TotauxBSEngie = 0;
        TotauxBSLagardere = 0;
        TotauxBSSFR = 0;
        TotauxDerem = 0;
        TotauxKiwatch = 0;
        TotauxSwitchcam = 0;
        TotauxBS = 0;
        TotauxCAR = 0;
        CountValideCall = 0;
        TotauxValideCall = 0;
        CountRegul = 0;
        TotauxRegul = 0;
        prime = 0;
        $('#LstCommission').find("[name='commissioncheckbox']").each(function(){
            if($(this).is(':checked')) {
                operator = $(this).closest('tr').find("[name='bs_operator']").val();
                contrat = $(this).closest('tr').find("[name='bs_contrat']").val();
                status = $(this).closest('tr').find("[name='bs_status']").val();
                comm = parseFloat($(this).closest('tr').find("[name='bs_comm']").val());
                car = parseFloat($(this).closest('tr').find("[name='bs_car_rem']").val());

                //CountBSEni
                if(operator == 1 && status == 9){
                    CountBSEni += 1;
                    TotauxBSEni += comm;
                }
                //Count BS Antargaz
                if(operator == 3 && status == 9){
                    CountBSAntargaz += 1;
                    TotauxBSAntargaz += comm;
                }
                //Count BS Planet
                if(operator == 5 && status == 9){
                    CountBSPlanet += 1;
                    TotauxBSPlanet += comm;
                }
                //Count BS Engie
                if(operator == 9 && status == 9){
                    CountBSEngie += 1;
                    TotauxBSEngie += comm;
                }
                //Count BS LAGARDERE
                if(operator == 11 && status == 9){
                    CountBSLagardere += 1;
                    TotauxBSLagardere += comm;
                }
                //Count BS SFR
                if(operator == 12 && status == 9){
                    CountBSSFR += 1;
                    TotauxBSSFR += comm;
                }
                //CountDerem
                if(status == 12){
                    CountDerem += 1;
                    TotauxDerem += comm;
                }
                //CountKiwatch
                if(operator == 2 && status == 9){
                    CountKiwatch += 1;
                    TotauxKiwatch += comm;
                }
                //CountSwitchCam
                if(operator == 13 && status == 9){
                    CountSwitchcam += 1;
                    TotauxSwitchcam += comm;
                }
                //CountValideCall
                if(status == 2){
                    CountValideCall += 1;
                    TotauxValideCall += comm;
                }
                //CountBS
                if(status == 9){
                    CountBSValide += 1;
                }
                //Statuts different de valide call et derem
                if(status != 2 && status != 12){
                    CountBS += 1;
                }
                if(status != 2 && operator != 5){
                    TotauxBS += comm;
                }
                if(status == 'regul'){
                    CountRegul += 1;
                    TotauxRegul += comm;
                }

                if(!isNaN(car) && operator == 1 && contrat == 1 && status == 9){
                    TotauxCAR += car;
                }

            }

        });

        $('#CountBSEni').text(CountBSEni);
        $('#CountBSAntargaz').text(CountBSAntargaz);
        $('#CountBSPlanet').text(CountBSPlanet);
        $('#CountBSEngie').text(CountBSEngie);
        $('#CountBSLagardere').text(CountBSLagardere);
        $('#CountBSSFR').text(CountBSSFR);
        $('#CountDerem').text(CountDerem);
        $('#CountKiwatch').text(CountKiwatch);
        $('#CountSWITCHCAM').text(CountSwitchcam);
        $('#CountValideCall').text(CountValideCall);
        $('#CountBsRegul').text(CountRegul);
        $('#CountBS').text(CountBS);

        $('#TotauxBSEni').text(TotauxBSEni.toFixed(2));
        $('#TotauxBSAntargaz').text(TotauxBSAntargaz.toFixed(2));
        $('#TotauxBSPlanet').text(TotauxBSPlanet.toFixed(2));
        $('#TotauxBSEngie').text(TotauxBSEngie.toFixed(2));
        $('#TotauxBSLagardere').text(TotauxBSLagardere.toFixed(2));
        $('#TotauxBSSFR').text(TotauxBSLagardere.toFixed(2));
        $('#TotauxDerem').text(TotauxDerem.toFixed(2));
        $('#TotauxKiwatch').text(TotauxKiwatch.toFixed(2));
        $('#TotauxSWITCHCAM').text(TotauxSwitchcam.toFixed(2));
        $('#TotauxValideCall').text(TotauxValideCall.toFixed(2));
        $('#TotauxBsRegul').text(TotauxRegul.toFixed(2));
        $('#TotauxCAR').text(TotauxCAR);

        prime = parseFloat(calcPrimeByPoint(CountBSValide));
        $('#UserPrime').text(prime.toFixed(2));
        $('#UserPrime').val(prime.toFixed(2));

        TotauxBS += prime;
        $('#TotauxBS').text(TotauxBS.toFixed(2)) ;

    });

    //Initialisation checkBox
    $('#commissionCheckAll').prop("checked", true);
    $('#LstCommission').find("[name='commissioncheckbox']").each(function(){
        $(this).prop("checked", true);
    });

    $('#commissionCheckAll').change(function(){
        if($(this).is(':checked')){
            $('#LstCommission').find("[name='commissioncheckbox']").each(function(){
                $(this).prop("checked", true);
            });
        }else{
            $('#LstCommission').find("[name='commissioncheckbox']").each(function(){
                $(this).prop("checked", false);
            });
        }
    });

    $('#ModalOnlyBSComm').on('show.bs.modal', function(e) {
        $(this).find('.btn-ok-onlycom').attr('href', $(e.relatedTarget).data('href'));
        $('.modalwait').show();
    });

    $('#btnPayeConfirmVendeur').click(function(){
        $("#ModalBSCommVendeur").modal("show");
    });

    // Hide the Modal
    $("#ModalBSCommVendeurClose").click(function(){
        $("#ModalBSCommVendeur").modal("hide");
    });


    $('#desactiveUser').change(function(){
        totalFG = parseFloat($('#TotauxValideCall').text());
        totalBS = parseFloat($('#TotauxBS').text());

        if($(this).is(':checked')){
            totalBS += totalFG;
        }
        else{
            totalBS -= totalFG;
        }

        if(!isNaN(totalBS)){
            $('#TotauxBS').text(totalBS.toFixed(2));
        }
    });

    $('#btnPayeCommission').click(function(){

        $("#ModalBSCommVendeur").modal("hide");
        $('.modalwait').show();

        var i = 0;
        var lstIdCommission = [];
        var users = $('#users').val();
        var prime = parseFloat($('#UserPrime').text());
        var desactiveUser = 0;
        if($('#desactiveUser').is(':checked')){
            desactiveUser = 1;
        }

        $('#LstCommission').find("[name='commissioncheckbox']").each(function() {
            if($(this)[0].name == 'commissioncheckbox' && $(this).is(':checked')){
                lstIdCommission[i] = $(this).attr("value");
                i++;
            }
        });

        if(i > 0) {
            var data = {
                _token: $(this).data('token'),
                lstIdCommission: lstIdCommission,
                users: users,
                prime: prime,
                desactiveUser: desactiveUser
            }

            $.ajax({
                type: 'POST',
                url: "/remuneration/commissionPaye",
                data: data,
                success: function(data) {
                    if (data) {
                        location.reload();
                        $('.modalwait').hide();
                    }
                }
                , error: function () {
                    $('.modalwait').hide();
                    alert('Erreur technique lors du paiement des commissions.');
                }
            });
        }
    });

    $('#btnDownloadCommVendeur').click(function() {
        $user = $('#users option:selected').text();

        $("#LstCommission").table2excel({
            exclude: ".noprint",
            name: "CommissionVendeur_" + $user,
            filename: "CommissionVendeur_" + $user,
            fileext: ".xls",
            exclude_links: true
        });
    });


    //Initialisation checkBox
    $('#commissionSwitchAll').prop("checked", true);
    $('#LstRemDistrib').find("[name='commissionSwitch']").each(function(){
        $(this).prop("checked", true);
    });

    $('#commissionSwitchAll').change(function(){
        if($(this).is(':checked')){
            $('#LstRemDistrib').find("[name='commissionSwitch']").each(function(){
                $(this).prop("checked", true);
            });
        }else{
            $('#LstRemDistrib').find("[name='commissionSwitch']").each(function(){
                $(this).prop("checked", false);
            });
        }
    });

    $('#LstRemDistrib').find("[name='commissionSwitch']").change(function(){
        if($(this).is(':checked')){
            $(this).closest('tr').removeClass('noprint');
        }
        else{
            $(this).closest('tr').addClass('noprint')
        }

        nbBS = 0;
        TotauxBS = 0;

        $('#LstRemDistrib').find("[name='commissionSwitch']").each(function() {
            if ($(this).is(':checked')) {
                nbBS++;
                comm = parseFloat($(this).closest('tr').find("[name='bs_comm']").val());

                TotauxBS += comm;
            }
        });

        $('#totalBS').text(nbBS) ;
        $('#totalCOM').text(TotauxBS.toFixed(2)) ;

    });

    $('#btnPayeConfirmDistrib').click(function(){
        $("#ModalBSRemDistrib").modal("show");
    });

    // Hide the Modal
    $("#ModalBSRemDistribClose").click(function(){
        $("#ModalBSRemDistrib").modal("hide");
    });


    $('#btnPayeRemDistrib').click(function(){

        $("#ModalBSRemDistrib").modal("hide");
        $('.modalwait').show();

        var i = 0;
        var lstIdCommission = [];

        $('#LstRemDistrib').find("[name='commissionSwitch']").each(function() {
            if($(this)[0].name == 'commissionSwitch' && $(this).is(':checked')){
                lstIdCommission[i] = $(this).attr("value");
                i++;
            }
        });

        if(i > 0) {
            var data = {
                _token: $(this).data('token'),
                lstIdCommission: lstIdCommission
            }

            $.ajax({
                type: 'POST',
                url: "/remuneration/switch/paye",
                data: data,
                success: function(data) {
                    if (data) {
                        location.reload();
                        $('.modalwait').hide();
                    }
                }
                , error: function () {
                    $('.modalwait').hide();
                    alert('Erreur technique lors du paiement des commissions.');
                }
            });
        }
    });

    // $('#btnDownloadCommDistrib').click(function() {
    //     $user = $('#distrib').text();
    //
        // $("#LstRemDistrib").table2excel({
        //     exclude: ".noprint",
        //     name: "CommissionDistributeur_" + $user,
        //     filename: "CommissionDistributeur_" + $user,
        //     fileext: ".xls",
        //     exclude_links: true
        // });
    // });

});

