/**
 * Created by florent.benoit on 19/01/2017.
 */


$(document).ready(function() {

    //Commission à verser Resp filtre auto
    $('#frmSearchCommissionResp :input').change(function() {
        date = $('#signature_date').val();
        users = $('#users').val();

        if(users != "" && date != ""){
            $('#submitFind').click();
        }
    });

    //Initialisation checkBox
    $('#commissionRespCheckAll').prop("checked", true);
    $('#LstCommissionResp').find("[name='commissionRespcheckbox']").each(function(){
        $(this).prop("checked", true);
    });

    $('#commissionRespCheckAll').change(function(){
        if($(this).is(':checked')){
            $('#LstCommissionResp').find("[name='commissionRespcheckbox']").each(function(){
                $(this).prop("checked", true);
            });
        }else{
            $('#LstCommissionResp').find("[name='commissionRespcheckbox']").each(function(){
                $(this).prop("checked", false);
            });
        }
    });

    $('#LstCommissionResp').find("[name='commissionRespcheckbox']").change(function(){
        if($(this).is(':checked')){
            $(this).closest('tr').removeClass('noprint');
        }
        else{
            $(this).closest('tr').addClass('noprint')
        }

        CountBSEni = 0;
        CountDerem = 0;
        CountKiwatch = 0;
        CountAntargaz = 0;
        CountPlanet = 0;
        CountSFR = 0;
        CountEngie = 0;
        CountCAR = 0;
        CountNBCAR = 0;
        CountBS = 0;
        TotauxBSEni = 0;
        TotauxDerem = 0;
        TotauxKiwatch = 0;
        TotauxAntargaz = 0;
        TotauxPlanet = 0;
        TotauxSFR = 0;
        TotauxEngie = 0;
        TotauxBS = 0;
        $('#LstCommissionResp').find("[name='commissionRespcheckbox']").each(function(){
            if($(this).is(':checked')) {
                bs_car = parseFloat($(this).closest('tr').find("[name='bs_car']").val());
                bs_nb_car = parseFloat($(this).closest('tr').find("[name='bs_nb_car']").val());
                bs_eni = parseFloat($(this).closest('tr').find("[name='bs_eni']").val());
                bs_kiwatch = parseFloat($(this).closest('tr').find("[name='bs_kiwatch']").val());
                bs_antargaz = parseFloat($(this).closest('tr').find("[name='bs_antargaz']").val());
                bs_planet = parseFloat($(this).closest('tr').find("[name='bs_planet']").val());
                bs_sfr = parseFloat($(this).closest('tr').find("[name='bs_sfr']").val());
                bs_engie = parseFloat($(this).closest('tr').find("[name='bs_engie']").val());
                bs_derem = parseFloat($(this).closest('tr').find("[name='bs_derem']").val());
                bs_commeni = parseFloat($(this).closest('tr').find("[name='bs_commeni']").val());
                bs_commkiwatch = parseFloat($(this).closest('tr').find("[name='bs_commkiwatch']").val());
                bs_commantargaz = parseFloat($(this).closest('tr').find("[name='bs_commantargaz']").val());
                bs_commplanet = parseFloat($(this).closest('tr').find("[name='bs_commplanet']").val());
                bs_commsfr = parseFloat($(this).closest('tr').find("[name='bs_commsfr']").val());
                bs_commengie = parseFloat($(this).closest('tr').find("[name='bs_commengie']").val());
                bs_commderem = parseFloat($(this).closest('tr').find("[name='bs_commderem']").val());


                CountCAR += bs_car;
                CountNBCAR += bs_nb_car;
                CountBSEni += bs_eni;
                TotauxBSEni += bs_commeni;

                CountDerem += bs_derem;
                TotauxDerem += bs_commderem;

                CountKiwatch += bs_kiwatch;
                TotauxKiwatch += bs_commkiwatch;

                CountAntargaz += bs_antargaz;
                TotauxAntargaz += bs_commantargaz;

                CountPlanet += bs_planet;
                TotauxPlanet += bs_commplanet;

                CountSFR += bs_sfr;
                TotauxSFR += bs_commsfr;

                CountEngie += bs_engie;
                TotauxEngie += bs_commengie;

                CountBS += bs_eni +  bs_kiwatch + bs_antargaz + bs_planet + bs_engie;
                TotauxBS += bs_commeni + bs_commderem + bs_commkiwatch + bs_commantargaz + bs_commplanet + bs_commengie;
            }

        });

        $('#totalCAR').text(CountCAR);
        $('#totalENI').text(CountBSEni);
        $('#totalKIWATCH').text(CountKiwatch);
        $('#totalANTARGAZ').text(CountAntargaz);
        $('#totalPLANET').text(CountPlanet);
        $('#totalSFR').text(CountSFR);
        $('#totalENGIE').text(CountEngie);

        $('#totalBSENI').text(CountBSEni);
        $('#totalBSDEREM').text(CountDerem);
        $('#totalBSKIWATCH').text(CountKiwatch);
        $('#totalBSANTARGAZ').text(CountAntargaz);
        $('#totalBSPLANET').text(CountPlanet);
        $('#totalBSSFR').text(CountSFR);
        $('#totalBSENGIE').text(CountEngie);
        $('#totalBS').text(CountBS);

        $('#totalCOMENI').text(TotauxBSEni.toFixed(2));
        $('#totalCOMDEREM').text(TotauxDerem.toFixed(2));
        $('#totalCOMKIWATCH').text(TotauxKiwatch.toFixed(2));
        if(isNaN(TotauxAntargaz)){
            TotauxAntargaz = 0;
        }
        $('#totalCOMANTARGAZ').text(TotauxAntargaz.toFixed(2));
        if(isNaN(TotauxPlanet)){
            TotauxPlanet = 0;
        }
        $('#totalCOMPLANET').text(TotauxPlanet.toFixed(2));
        if(isNaN(TotauxPlanet)){
            TotauxEngie = 0;
        }
        $('#totalCOMENGIE').text(TotauxEngie.toFixed(2));


        //Commission en fct du MW/h
        prime_var = 0;
        if(CountNBCAR > 0) {
            moy_Car = CountCAR / CountNBCAR;

            if (moy_Car > 15000) {
                prime_var = (0.0001 * CountCAR);
            } else {
                prime_var = (0.00005 * CountCAR);
            }
            $('#UserPrime').text(prime_var.toFixed(2));
        }

        //
        prime = parseFloat($('#UserPrime').text()) + parseFloat($('#UserPrimeFixe').text());
        TotauxBS += prime;
        $('#totalCOM').text(TotauxBS.toFixed(2)) ;

    });

    $('#btnPayeConfirmResp').click(function(){
        $("#ModalBSCommResp").modal("show");
    });

    // Hide the Modal
    $("#ModalBSCommRespClose").click(function(){
        $("#ModalBSCommResp").modal("hide");
    });


    $('#btnPayeCommissionResp').click(function(){

        $("#ModalBSCommResp").modal("hide");
        $('.modalwait').show();

        var i = 0;
        var lstIdVendeur = [];
        var users = $('#users').val();
        var datesignature = $('#signature_date').val();
        var primevar = $('#UserPrime').val();

        $('#LstCommissionResp').find("[name='commissionRespcheckbox']").each(function() {
            if($(this)[0].name == 'commissionRespcheckbox' && $(this).is(':checked')){
                lstIdVendeur[i] = $(this).attr("value");
                i++;
            }
        });

        if(i > 0) {
            var data = {
                _token: $(this).data('token'),
                lstIdVendeur: lstIdVendeur,
                users: users,
                signature_date: datesignature,
                primevar: primevar
            }

            $.ajax({
                type: 'POST',
                url: "/remuneration/commissionPayeResp",
                data: data,
                success: function(response) {
                    location.reload();
                    $('.modalwait').hide();
                }
                , error: function (response) {
                    $('.modalwait').hide();
                    alert('Erreur technique lors du paiement des commissions des responsables.');
                }
            });
        }
    });

    $('#btnDownloadCommChef').click(function() {
        $user = $('#users option:selected').text();

        $("#LstCommissionResp").table2excel({
            exclude: ".noprint",
            name: "CommissionResponsable_" + $user,
            filename: "CommissionResponsable_" + $user,
            fileext: ".xls",
            exclude_links: true
        });
    });

    $('#btnDownloadCommDistrib').click(function () {
        $('#submitDownloadCommDistrib').click();
    });

});
