$(document).ready(function() {
    $('#frmEditProspection').formValidation({
        framework: "uikit",
        trigger: 'blur',
        button: {
            selector: '#btnSubmit',
            disabled: 'disabled'
        },
        fields: {
            last_name: {
                validators: {
                    notEmpty: {
                        message: "Le nom est requis"
                    }
                }
            },
            first_name: {
                validators: {
                    notEmpty: {
                        message: "Le prénom est requis"
                    }
                }
            },
            birth_date: {
                validators: {
                    callback: {
                        message: 'La date de naissance est requise',
                        callback: function(value, validator, $field) {
                            if(document.getElementById('birth_date').value){
                                return true;
                            }
                            return false;
                        }
                    },
                    date: {
                        format: "DD/MM/YYYY",
                        min:  new Date((new Date().getYear() - 76), (new Date().getMonth()), (new Date().getDay())),
                        message: "L'age doit être inférieur à 75 ans"
                    }
                }
            },
            email: {
                validators: {
                    emailAddress: {
                        message: 'La valeur n\'est pas une adresse e-mail valide'
                    },
                    remote: {
                        url: '/prospections/checkmail',
                        method: 'GET',
                        data: function() {
                            return {
                                email: document.getElementById('email').value,
                                last_name: document.getElementById('last_name').value,
                                first_name: document.getElementById('first_name').value
                            };
                        },
                        message: 'E-mail existant',
                    }
                }
            },
            address: {
                validators: {
                    notEmpty: {
                        message: "L'adresse est requise"
                    }
                }
            },
            zip_code: {
                verbose: false,
                validators: {
                    notEmpty: {
                        message: "Le code postal est requis"
                    }
                }
            },
            city: {
                validators: {
                    notEmpty: {
                        message: "La ville est requise"
                    }
                }
            },
            phone_1: {
                validators: {
                    notEmpty: {
                        message: "Le téléphone est requis"
                    },
                    remote: {
                        message: 'Le téléphone existe déjà',
                        url: '/prospections/control',
                        data:function(validator, $field, value) {
                            return {
                                type: 'phone_1',
                                phone_1: validator.getFieldElements('phone_1').val()
                            };
                        },
                        type: 'GET'
                    },
                    integer: {
                        message: 'La valeur n\'est pas un chiffre'
                    }
                }
            },
            phone_2: {
                validators: {
                    integer: {
                        message: 'La valeur n\'est pas un chiffre'
                    },
                    remote: {
                        message: 'Le téléphone existe déjà',
                        url: '/prospections/control',
                        data:function(validator, $field, value) {
                            return {
                                type: 'phone_1',
                                phone_1: validator.getFieldElements('phone_2').val()
                            };
                        },
                        type: 'GET'
                    },
                }
            },
            phone_3: {
                validators: {
                    integer: {
                        message: 'La valeur n\'est pas un chiffre'
                    },

                    remote: {
                        message: 'Le téléphone existe déjà',
                        url: '/prospections/control',
                        data:function(validator, $field, value) {
                            return {
                                type: 'phone_1',
                                phone_1: validator.getFieldElements('phone_3').val()
                            };
                        },
                        type: 'GET'
                    },
                }
            }
        }
    });


});