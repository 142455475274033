var jqAjax = {
    modules : {}
};
var url = window.location.href;

jqAjax.modules.makeSelect = ( function(){
    return {
        getRegionChildren: function(id, callback){
            $.ajax({
                url: '/bs/region/'+ id,
                type: 'GET',
                dataType: 'json',
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },

        getAgencyChildren: function(id, callback){
            $.ajax({
                url: '/bs/agency/'+ id,
                type: 'GET',
                dataType: 'json',
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },

        getTeamChildren: function(id, callback){
            $.ajax({
                url: '/bs/team/'+ id,
                type: 'GET',
                dataType: 'json',
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },

        getSellerChildren: function(id, callback){
            $.ajax({
                url: '/bs/seller/'+ id,
                type: 'GET',
                dataType: 'json',
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },

        getUserInSameStructure: function(id, callback){
            $.ajax({
                url: '/bs/usersinstructure/'+ id,
                type: 'GET',
                dataType: 'json',
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        }
    }
})();

jqAjax.modules.autoComplete = ( function(){
    return{
        bsfilter_autocomplete: function(callback){
            $.ajax({
                url: '/bs/autocomplete/bs',
                type: 'GET',
                dataType: 'json',
                data : {
                    numBs : $('#numBS').val()
                },
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },

        client_autocomplete: function(callback){
            $.ajax({
                url: '/bs/autocomplete/client',
                type: 'GET',
                dataType: 'json',
                data : {
                    client : $('#client').val()
                },
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        }
    }

})();

jqAjax.modules.changeSelect =( function(){
    return{
        changeRegionSelect: function(data){
            $('#region').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            $('#agency').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            $('#team').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            data.forEach(function(data_info){
                $('#region').append('<option value="'+data_info.id+'">'+data_info.name+'</option>');
            });
        },

        changeAgencySelect: function(data){
            $('#agency').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            $('#team').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            data.forEach(function(data_info){
                $('#agency').append('<option value="'+data_info.id+'">'+data_info.name+'</option>');
            });
        },

        changeTeamSelect: function(data){
            $('#team').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            data.forEach(function(data_info){
                $('#team').append('<option value="'+data_info.id+'">'+data_info.name+'</option>');
            });
        },

        changeSellerSelect: function(data){
            $('#seller').find('option')
                .remove()
                .end()
                .append('<option value="0">Tous</option>');
            data.forEach(function(data_info){
                $('#seller').append('<option value="'+data_info.id+'">'+data_info.last_name+ ' '+ data_info.first_name+'</option>');
            });
        },

        changeUserSelect: function(data){
            $('#users').find('option')
                .remove()
                .end()
                .append('<option value="0"></option>');
            data.forEach(function(data_info){
                $('#users').append('<option value="'+data_info.id+'">'+data_info.last_name+ ' '+ data_info.first_name+'</option>');
            });
        }
    }

})();

jqAjax.modules.changecar = (function(){
    return{
        cleanValue : function(){
            $('#from').val("");
            $('#to').val("");
        },

        baseValue : function(){
            $('#from').val("0");
            $('#to').val("999");
        },

        b0Value : function(){
            $('#from').val("1000");
            $('#to').val("6000");
        },
        b1Value : function(){
            $('#from').val("6001");
            $('#to').val("30000");
        },
        b2iValue : function(){
            $('#from').val("30000");
            $('#to').val("");
        }
    }
}) ();

$(document).ready(function() {
    // $('#distributor').on('change', function(){
    //     jqAjax.modules.makeSelect.getRegionChildren($(this).val(), jqAjax.modules.changeSelect.changeRegionSelect);
    // });
    //
    // $('#region').on('change', function(){
    //     jqAjax.modules.makeSelect.getAgencyChildren($(this).val(), jqAjax.modules.changeSelect.changeAgencySelect);
    // });
    //
    // $('#agency').on('change', function(){
    //     jqAjax.modules.makeSelect.getTeamChildren($(this).val(), jqAjax.modules.changeSelect.changeTeamSelect );
    // });
    //
    // $('#team').on('change', function(){
    //     jqAjax.modules.makeSelect.getSellerChildren($(this).val(), jqAjax.modules.changeSelect.changeSellerSelect );
    // });

    $('#structure').not('#frmSearchCommission #structure').on('change', function(){
            jqAjax.modules.makeSelect.getUserInSameStructure($(this).val(), jqAjax.modules.changeSelect.changeUserSelect );
    });


    $('#gas').on('change', function(){
       switch ( $('#gas option:selected').val()){
           case '1':
               jqAjax.modules.changecar.cleanValue();
               break;
           case '2':
               jqAjax.modules.changecar.cleanValue();
               break;
           case '3':
               jqAjax.modules.changecar.baseValue();
               break;
           case '4':
               jqAjax.modules.changecar.b0Value();
               break;
           case '5':
               jqAjax.modules.changecar.b1Value();
               break;
           case '6':
               jqAjax.modules.changecar.b2iValue();
               break;
       }
    });

    $('#numBS').autocomplete({
        source : function(request, response){
            jqAjax.modules.autoComplete.bsfilter_autocomplete(function(data){
                response($.map(data, function(object){
                    return  object.number;
                }));
            });
        },
        select: function( event, ui ) {
            $('#numBS').val(ui.item.value);
            $('#submitFind').click();
        },
        minLength :2
    });

    // $('#client').autocomplete({
    //     source : function(request, response){
    //         jqAjax.modules.autoComplete.client_autocomplete(function(data){
    //             response($.map(data, function(object){
    //                 return object.id;
    //             }));
    //         });
    //     },
    //     minLength :2
    // });

    $('#frmSearchBs input[type=radio]').click(function() {
        if ($(this).hasClass("imChecked")) {
            $(this).removeClass("imChecked");
            $(this).prop('checked', false);
        } else {
            $(this).prop('checked', true);
            $(this).addClass("imChecked");
        };
        $('#submitFind').click();
    });

    // Ecouteurs sur les champs
    $('#frmSearchBs :input').change(function() {
         $('#submitFind').click();
    });

    if ($('#frmSearchBs').length > 0) {
        document.querySelector('#frmSearchBs').addEventListener('keypress', function (e) {
            var key = e.which || e.keyCode;
            if (key === 13) { // 13 is enter
                $('#submitFind').click();
            }
        });
    }


});


