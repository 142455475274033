/**
 * Created by florent.benoit on 04/01/2017.
 */


$(document).ready(function() {

    $("SessionUser").val(GetSessionUser());

});


function SetSessionUser(value)
{
    SessionUser = value;
}

function GetSessionUser()
{
    SessionUser = 6;
    return SessionUser;
}


