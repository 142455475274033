
$(document).ready(function() {

    var selector_selected = new Array();

    $('#formImport').find('select').each(function () {
        selector_selected.push( $(this).find(":selected").val());
    });

    $('#formImport').find('select').each(function () {
        $($(this)).data('pastVal', $(this).val());

        ($(this).find('option')).each(function(){
            var i = 0;
            while(i < selector_selected.length){
                if($(this).val() == selector_selected[i] && selector_selected[i] != "N"){
                    $(this).hide();
                }
                i++;
            }
        });

    }).on('change', function(){
        ($(this).find('option')).each(function(){
            var i = 0;
            while(i < selector_selected.length){
                if($(this).val() == selector_selected[i] && selector_selected[i] != "N"){
                    $(this).hide();
                }
                i++;
            }
        });

        $(this).find("option[value='"+ $(this).data('pastVal')+"']").show();
    });



    $('#formImport').find('select').each(function () {
        $($(this)).data('previousVal', $(this).val());
        $($(this)).data('previousText', $(this).find(":selected").text());
    }).on('change', function() {
        var self = $(this);
        var value = self.val();
        var previousVal = $(self).data('previousVal');

        if (value !== "N") {
            $("select").not(self).find("option[value='" + value +"']").remove();
        }

        if (previousVal !== "N") {
            $("select").not(self).append("<option value='" +  previousVal + "'>" +  $(self).data('previousText') + "</option>")
                .each(function () {
                    var previous = $(this).val();
                    var options = $(this).children().sort(function(a,b) {
                        if (a.value == 'N') {
                            return -1;
                        } else if (b.value == 'N') {
                            return 1;
                        } else {
                            return a.text.localeCompare(b.text);
                        }
                    });
                    $(this).empty().append(options);
                    $(this).val(previous);
                });
        }

        $(self).data('previousVal', value);
        $(self).data('previousText', self.find(":selected").text());
    });

    $('#frmImportBsField').submit(function(){
        $('.modalwait').show();
    });

    $('#frmImportBsFile').submit(function(){
        if($('input[type=radio][name=Type_Fichier]:checked').val() == "ENI_DEREM" || "KIWATCH"){
            $('.modalwait').show();
        }
    });

    $('#frmImportRem').submit(function(){
        $('.modalwait').show();
    });

});
