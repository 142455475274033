"use strict";

$(document).ready(function() {
    $('#frmEditCluster').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            city_id: {
                validators: {
                    notEmpty: {
                        message: "La ville est requis"
                    }
                }
            },
            date_debut: {
                validators: {
                    notEmpty: {
                        message: "Le date de début est requis"
                    }
                }
            },
            date_fin: {
                validators: {
                    notEmpty: {
                        message: "Le date de fin est requis"
                    }
                }
            },

            objectif: {
                validators: {
                    notEmpty: {
                        message: "L'objectif est requis"
                    }
                }
            }
        }
    });
});