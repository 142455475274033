

$(document).ready(function() {


    $('#btnDownloadChallenge').click(function () {
        $("#lstChallenge").table2excel({
            exclude: ".noprint",
            name: "lstChallenge",
            filename: "lstChallenge",
            fileext: ".xls",
            exclude_links: true
        });
    });

});


// document.addEventListener("DOMContentLoaded", function(event) {
//     lava.events.emit('jsapi:ready', window.google);
// });

