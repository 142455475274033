"use strict";

$(document).ready(function() {
    $('#frmEditUser').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            last_name: {
                validators: {
                    notEmpty: {
                        message: "Le nom de l'utilisateur est requis"
                    }
                }
            },
            first_name: {
                validators: {
                    notEmpty: {
                        message: "Le prénom de l'utilisateur est requis"
                    }
                }
            },
            login: {
                validators: {
                    regexp: {
                        message: "Le login ne doit pas contenir de caractère spéciaux et d'espaces",
                        regexp: /^[A-Za-z0-9@._-]+$/i
                    },
                }
            },
            password: {
                validators: {
                    regexp: {
                        message: "Le mot de passe doit contenir au moins une majusculte, un chiffre et un caractère spécial",
                        regexp: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/i
                    },
                    identical: {
                        field: "cpassword",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            },
            cpassword: {
                validators: {
                    identical: {
                        field: "password",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            },
            'IdRole[]': {
                validators: {
                    notEmpty: {
                        message: 'Merci de sélectionner un rôle'
                    }
                }
            },
            'IdStructure[]': {
                validators: {
                    notEmpty: {
                        message: 'Merci de sélectionner une structure'
                    }
                }
            }
        }
    });

    $('#frmEditVendeur').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            last_name: {
                validators: {
                    notEmpty: {
                        message: "Le nom de l'utilisateur est requis"
                    }
                }
            },
            first_name: {
                validators: {
                    notEmpty: {
                        message: "Le prénom de l'utilisateur est requis"
                    }
                }
            },
            login: {
                validators: {
                    regexp: {
                        message: "Le login ne doit pas contenir de caractère spéciaux et d'espaces",
                        regexp: /^[A-Za-z0-9]+$/i
                    },
                }
            },
            password: {
                validators: {
                    regexp: {
                        message: "Le mot de passe doit contenir au moins une majusculte, un chiffre et un caractère spécial",
                        regexp: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/i
                    },
                    identical: {
                        field: "cpassword",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            },
            cpassword: {
                validators: {
                    identical: {
                        field: "password",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            },
            "IdStructure[]": {
                validators: {
                    notEmpty: {
                        message: 'Merci de sélectionner une structure'
                    }
                }
            }
        }
    });
});
