$(document).ready(function(){

    //Stats report filtre auto
    $('#frmSearchReport :input').change(function() {
        $('#submitFind').click();
    });

    //Stats report filtre auto
    $('#frmSearchExtract :input').change(function() {
        $('#submitFind').click();
    });

    //Objectif filtre auto
    $('#frmSearchObjectif :input').change(function() {
        $('#submit').click();
    });

    // Ecouteurs sur les champs
    $('#frmSearchCluster :input').change(function() {
        $('#submit').click();
    });

    if ($('#frmSearchCluster').length > 0) {
        document.querySelector('#frmSearchCluster').addEventListener('keypress', function (e) {
            var key = e.which || e.keyCode;
            if (key === 13) { // 13 is enter
                $('#submit').click();
            }
        });
    }


    $('#btnDownloadExtract').click(function() {
        $("#lstExtract").table2csv({
            filename: "Taux de rétractation.csv",
            separator:';',
            excludeRows:'.noprint'
        });
    });

});
