/**
* Created by florent.benoit on 18/10/2016.
*/

$(function() {
    /* Champs avec date période */
    $('input[name="DateRange"]').daterangepicker({
        autoUpdateInput: false,
        showDropdowns:true,
        locale: {
            format: 'DD/MM/YYYY'
        }
    });

    $('input[name="DateRange"]').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });

    $('input[name="DateRange"]').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });


    /* Champs date simple */
    $(".SingleDate").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        minDate: '01/01/1900',
        maxDate: '01/01/2050',
        locale: {
            format: 'DD/MM/YYYY'
        }
    });
    $(".SingleDate").on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });


    /* Champs date simple pour la prise de Rendez-vous (prospection)  */
    $(".SingleDateRdv").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: true,
        minDate: '01/01/1900',
        maxDate: '01/01/2050',
        locale: {
            format: 'DD/MM/YYYY'
        }
    });
    $(".SingleDateRdv").on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });

    /* Champs date simple */
    $(".DateCustom").daterangepicker({
        // //singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        minDate: '01/01/2015',
        maxDate: '01/01/2050',
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: "Clear",
            customRangeLabel: "Plage de dates",
            daysOfWeek: [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            firstDay: 1
        },
        linkedCalendars: false,
        showBeforeDate: true,
        showAfterDate: true,
        showSpecificDate: true,
        ranges: {
            'Aujourd\'hui': [moment(), moment()],
            '30 derniers jours': [moment().subtract(29, 'days'), moment()],
            'Mois en cours': [moment().startOf('month'), moment().endOf('month')],
            'Année en cours': [moment().startOf('year'), moment()],
            'Mois précédent': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        },
    });

    $('.DateCustom').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        $('#submitFind').click();
    });

    $('.DateCustom').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

    /* Champs date simple */
    $(".DateCustom2022").daterangepicker({
        // //singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        minDate: '01/03/2022',
        maxDate: '01/01/2050',
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: "Clear",
            customRangeLabel: "Plage de dates",
            daysOfWeek: [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            firstDay: 1
        },
        linkedCalendars: false,
        showBeforeDate: true,
        showAfterDate: true,
        showSpecificDate: true,
        ranges: {
            'Aujourd\'hui': [moment(), moment()],
            '30 derniers jours': [moment().subtract(29, 'days'), moment()],
            'Mois en cours': [moment().startOf('month'), moment().endOf('month')],
            'Année en cours': [moment().startOf('year'), moment()],
            'Mois précédent': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        },
    });

    $('.DateCustom2022').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        $('#submitFind').click();
    });

    $('.DateCustom2022').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

});

$(document).ready(function() {
    /* Masque de saisie date naissance */
    $('#birth_date').mask("00/00/0000", {placeholder: "__/__/____"});

    $('#birth_date').keyup(function(){
        if($('#birth_date').val().length == 5){
            $birthdate = $('#birth_date').val();
            $('#birth_date').val($birthdate + '/19');
        }
    });

    /* Masque de saisie date naissance */
    $('.signatureDate').mask("00/00/0000", {placeholder: "__/__/____"});

    $('.signatureDate').keyup(function(){
        if($('.signatureDate').val().length == 5){
            $signature_date = $('.signatureDate').val();
            $('.signatureDate').val($signature_date + '/20');
        }
    });



});


