$(document).ready(function() {

    //Stats report filtre auto
    $('.edit-payplan').click(function (e) {
        var payplan_id = $(e.target).data('payplan-id');
        var seller_name = $(e.target).data('seller-name');
        var user_id = $(e.target).data('user-id');
        var start_month = $(e.target).data('start_month');
        var end_month = $(e.target).data('end_month');

        $('#seller_name').text(seller_name);
        $('#user_id').val(user_id);
        $('#date_debut').val(start_month);
        $('#date_fin').val(end_month);

        if(payplan_id > 0){
            $('#payplan_id option[value=' + payplan_id + ']').attr('selected','selected');
        }
    });

});

