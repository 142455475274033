/**
 * Created by florent.benoit on 21/03/2017.
 */

function RoleStrucChange() {
    var val = $('#roleStruc').val(); // on récupère la valeur du type de groupe
    if (val != 0) {
        $('#structure').empty(); // on vide la liste structure parent

        $.ajax({
            type: 'GET',
            url: '/structure/GetLstStructureParent',
            data: 'id_groupe=' + val, // on envoie $_GET['id_groupe']
            dataType: 'json',
            success: function (json) {
                $.each(json, function (index, value) {
                    $('#structure').append('<option value="' + index + '">' + value + '</option>');
                });
                $('#structure').prop('disabled', false);
            }
        });
    } else {
        $('#structure').val(0);
        $('#structure').prop('disabled', true);
    }
}
