$(document).ready(function() {


    if ($('#lstConflit').find('tr').length <= 1)
    {
        $('#btnDeleteTabConflit').hide();
    }else{
        $('#btnDeleteTabConflit').show();
    }

    $('#conflitcheckboxAll').change(function(){
        if($(this).is(':checked')){
            $('#lstConflit').find('input').each(function(){
                $(this).prop("checked", true);
            });
        }else{
            $('#lstConflit').find('input').each(function(){
                $(this).prop("checked", false);
            });
        }
    });

    $('#btnDeleteTabConflit').click(function(){

        var i = 0;
        var lstIdConflit = [];

        $('#lstConflit').find('input').each(function() {
            if($(this)[0].name == 'conflitcheckbox[]' && $(this).is(':checked')){
                lstIdConflit[i] = $(this).attr("value");
                i++;
            }
        });

        if(i > 0) {
            var data = {
                _token: $(this).data('token'),
                lstBsIdConflits: lstIdConflit
            }

            $.ajax({
                type: 'POST',
                url: "/bs/conflits/massive_delete",
                data: data
                , success: function (data) {
                    window.location.replace(data);
                }
                , error: function () {
                    alert('Erreur technique lors de la suppression des conflits.');
                }
            });
        }
    });

    $('#btnDownload').click(function () {
        $('#submitDownload').click();
    });

});
